<template>
    <div>
        <div class="d-flex justify-content-start align-items-center my-10">
            Toplam {{ totalItems }} müşteri bulundu.
        </div>
        <div class="d-flex align-items-center justify-content-between search-list-area">
            <div class="d-flex align-items-center search-input">


                <Input placeholder="Ara.." v-model="search" class="mr-2" />
            </div>
            <div class="d-flex align-items-center search-buttons">
                <div class="d-flex align-items-center">
                    <b-form-group>
                    <b-button variant="outline-light" @click="exportOperations(false)" class="mr-2">DIŞA
                        AKTAR
                    </b-button>
                </b-form-group>
                <b-form-group >
                    <b-button variant="outline-light" @click="openImportModal()" class="mr-2">DOSYA
                        YÜKLE
                    </b-button>
                </b-form-group>
                <b-form-group>
                    <b-dropdown id="dropdown-action-table" variant="outline-light" text="Toplu İşlemler" class="mr-2">
                        <b-dropdown-item @click="actions('allInActive')">Seçilenleri Pasife Çek</b-dropdown-item>
                        <b-dropdown-item @click="actions('allActive')">Seçilenleri Aktife Çek</b-dropdown-item>
                        <b-dropdown-item @click="actions('allRemove')">Seçilenleri Kaldır</b-dropdown-item>
                        <b-dropdown-item @click="actions('allExport')">Seçilenleri Dışa Aktar</b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
                </div>
                
               <div class="d-flex align-items-center">
                <b-form-group>
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <span>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 2.40898V9.92754C0 10.7825 0.336953 11.6022 0.94045 12.2057L9.79175 21.057C11.049 22.3143 13.0858 22.3143 14.3431 21.057L21.057 14.3431C22.3143 13.0858 22.3143 11.049 21.057 9.79176L12.2057 0.940467C11.6022 0.33697 10.7825 1.74727e-05 9.92753 1.74727e-05H2.41399C1.08127 -0.00501167 0 1.07625 0 2.40898ZM5.63264 4.0183C6.05946 4.0183 6.4688 4.18786 6.77061 4.48966C7.07242 4.79147 7.24197 5.20081 7.24197 5.62763C7.24197 6.05445 7.07242 6.46379 6.77061 6.76559C6.4688 7.0674 6.05946 7.23695 5.63264 7.23695C5.20582 7.23695 4.79648 7.0674 4.49468 6.76559C4.19287 6.46379 4.02332 6.05445 4.02332 5.62763C4.02332 5.20081 4.19287 4.79147 4.49468 4.48966C4.79648 4.18786 5.20582 4.0183 5.63264 4.0183Z"
                                        fill="#102640" fill-opacity="0.5" />
                                </svg></span>
                        </template>
                        <b-dropdown-item href="#">En Yüksek Gelire Göre</b-dropdown-item>
                        <b-dropdown-item href="#">En Düşük Gelire Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Artan Mülk Sayısına Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Azalan Mülk Sayısına Göre</b-dropdown-item>
                        <b-dropdown-item href="#">Favoriler</b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
                <b-form-group>
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <span><i class="flaticon-star"></i></span>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here...</b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
                <b-form-group>
                    <router-link to="/musteriler/musteri-ekle" v-slot="{ href, navigate, isActive, isExactActive }">

                        <b-button variant="primary" class="ml-auto" :href="href" @click="navigate">Müşteri Ekle</b-button>
                    </router-link>
                </b-form-group>
               </div>
                
            </div>
        </div>


        <div class="custom-list">
            <List :header="header" :data="items" :pagination="pagination" :loading="loading" @updatePage="updatePage"
                moduleName="Sistem Kullanıcı Listesi" :moduleCreateEvent="true" :moduleSearch="search"
                @selectedItemIdsChanged="selectedItemIdsChanged" @openCreateModal="openInsertUpdate('')">

                <template v-slot:item.email="{ item }">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>
                <template v-slot:item.isActive="{ item }">
                    <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
                </template>
                <template v-slot:item.star="{ item }">
                    <div v-html="$customFunctions.getIsStar(item.star)"></div>
                </template>
                <template v-slot:item.action="{ item }" class="p-0">
                    <b-dropdown size="sm" no-caret dropleft variant="muted">
                        <template v-slot:button-content>
                            <i class="ki ki-bold-more-ver text-dark"></i>
                        </template>
                        <b-dropdown-item @click="openDetail(item)">Detay</b-dropdown-item>
                        <b-dropdown-item @click="openInsertUpdate(item)">Düzenle</b-dropdown-item>
                        <b-dropdown-item @click="openDeleteHandler(item)">Sil</b-dropdown-item>
                    </b-dropdown>
                </template>
            </List>
            <!-- Create and Actions Popups -->
            <InsertUpdateModal :title="selectedItem == ''
                ? 'Sistem Kullanıcı Kaydı Ekle'
                : 'Sistem Kullanıcını Düzenle'
                " :show="showInsertUpdate" @closeDetail="closeInsertUpdate()" @confirm="confirmModal()"
                :resetForm="resetForm">
                <InsertUpdateOperations :selectedItem="selectedItem" :confirmModal="confirmCreate"
                    @resetInsertUpdateForm="resetInsertUpdateForm" />
            </InsertUpdateModal>

            <DetailModal title="Sistem Kullanıcısı Detay" :show="showDetail"
                @openInsertUpdate="openInsertUpdate(selectedItem)" @closeDetail="closeDetail">
                <DetailView :selectedItem="selectedItem" />
            </DetailModal>

            <ImportModal :show="showImportModal" importApiEndpoint="user/import" @closeDetail="closeImportModal()"
                @updateList="getList" @downloadSampleFile="exportOperations(true)" />

            <DeleteOperation :url="deleteOperationUrl" :params="deleteIds" @updateList="getList" />
        </div>
    </div>
</template>

<style lang="scss">
    @media (max-width:990px) {
        .search-list-area{
            flex-direction: column;
            .search-input{
                width: 100%;
                *{
                    width: 100%;
                }
            }
        }
        .search-buttons{
            flex-direction: column;
        }
    }

</style>

<script>

export default {
    name: 'customerList',
    data() {
        return {
            search: '',
            filters: '',
            showInsertUpdate: false,
            showDetail: false,
            showImportModal: false,
            resetForm: false,
            confirmCreate: false,
            loading: false,
            header: [
                { text: '', value: 'id', checkboxField: true, size: '50px' },
                { text: '', value: 'star', size: '50px' },
                { text: 'Adı - Soyadı', value: 'fullName' },
                { text: 'Adı - Soyadı', value: 'fullName' },
                { text: 'Adı - Soyadı', value: 'fullName' },
                { text: 'Adı - Soyadı', value: 'fullName' },
                { text: 'Email', value: 'email' },
                { text: 'Durum', value: 'isActive', size: '60px' },
                { text: '', value: 'action', size: '60px' },
            ],
            items: [],
            pagination: {},
            meta: {},
            listSelectedItemIds: [],
            selectedItem: '',
            deleteOperationUrl: '',
            deleteIds: '',
        };
    },

    created() {
        this.getList();
    },
    methods: {

        getList() {

            this.loading = true;
            this.items = [
                {
                    id: '1',
                    fullName: 'Burak Kaşıkcı',
                    email: 'burak@dveb.com.tr',
                    star: true,
                    isActive: true,
                },
                {
                    id: '2',
                    fullName: 'Fatih Çamcıoğlu',
                    email: 'fatih@dveb.com.tr',
                    star: true,
                    isActive: true,
                },
                {
                    id: '3',
                    fullName: 'Yavuz Dönmez',
                    email: 'yavuz@dveb.com.tr',
                    star: false,
                    isActive: true,
                },
            ];
            this.totalItems = 3;

            this.pagination = {
                current_page: 1,
                total_pages: 1,
                total_items: 3,
            };
            this.loading = false;
            if (this.search) {
                const searchValue = this.search;
                const foundItems = this.items.filter(item => {
                    for (const prop in item) {
                        if (item[prop].toString().toLowerCase().includes(searchValue)) {
                            return true;
                        }
                    }
                    return false;
                });
                this.items = foundItems
            }

            // this.items = [];
            // this.totalItems = 0;

            // let queryLimit = this.perPage ? this.perPage : 10;
            // let query = `user/list?Page=${
            //   this.page ? this.page : 1
            // }&limit=${queryLimit}`;
            // if (this.search) {
            //   query += `&search=${this.search}`;
            // }

            // this.$dbFunctions
            //   .get(query)
            //   .then((res) => {
            //     if (res.success == true && res.count > 0) {
            //       this.items = res.data;
            //       this.totalItems = res.count;

            //       this.pagination = {
            //         current_page: res.page,
            //         total_pages: res.pageCount,
            //         total_items: res.count,
            //       };
            //     }
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   })
            //   .finally(() => {
            //     this.loading = false;
            //   });
        },
        updatePage(value) {
            this.meta = value;

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        confirmModal() {
            this.confirmCreate = true;
            setTimeout(() => {
                this.confirmCreate = false;
            }, 200);
        },
        resetInsertUpdateForm(closeInsertUpdate = false) {
            if (closeInsertUpdate) {
                this.closeInsertUpdate();
                this.getList();
            }

            this.resetForm = true;
            setTimeout(() => {
                this.resetForm = false;
            }, 200);
        },
        closeInsertUpdate() {
            setTimeout(() => {
                this.selectedItem = '';
            }, 100);
            this.showInsertUpdate = false;
        },
        openInsertUpdate(item) {
            this.selectedItem = item;
            this.showInsertUpdate = true;
        },
        closeDetail() {
            this.showDetail = false;
        },
        openDetail(item) {
            this.selectedItem = item;
            this.showDetail = true;
        },
        closeImportModal() {
            this.showImportModal = false;
        },
        openImportModal() {
            this.showImportModal = true;
        },
        openDeleteHandler(item) {
            this.deleteOperationUrl = `user`;
            this.deleteIds = item.id;
            this.$bvModal.show('modal-deleteOperation');
        },
        selectedItemIdsChanged(payload) {
            this.listSelectedItemIds = payload;
        },
        actions(payload) {
            if (
                this.listSelectedItemIds === [] ||
                this.listSelectedItemIds.length == 0
            ) {
                this.$generateNotification(
                    this,
                    'warning',
                    'İşlem yapılacak seçili kayıt bulunamadı!',
                    'İşleme devam edilemiyor.'
                );
            } else {
                if (payload == 'allRemove') {
                    this.deleteOperationUrl = `user`;
                    this.deleteIds = this.listSelectedItemIds;
                    this.$bvModal.show('modal-deleteOperation');
                } else if (payload == 'allInActive' || payload == 'allActive') {
                    this.loading = true;
                    this.$dbFunctions
                        .update(`user/update-status`, {
                            ids: this.listSelectedItemIds,
                            isActive: payload == 'allActive',
                        })
                        .then((res) => {
                            this.$dbFunctions.setResponse(this, res);
                            this.getList();
                        })
                        .catch((err) => {
                            this.$dbFunctions.setResponse(this, err);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else if (payload == 'allExport') {
                    this.exportOperations(false);
                }
            }
        },
        exportOperations(onlyHeaders) {
            this.loading = true;
            let payload = {
                Ids: this.listSelectedItemIds,
                onlyHeaders: onlyHeaders,
            };

            this.$dbFunctions
                .insertOrUpdate(`user/export`, 'export', payload)
                .then((res) => {
                    // this.$customFunctions.downloadFile(res.data);

                    this.$generateNotification(
                        this,
                        'success',
                        `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
                        'Dosyanız hazır!'
                    );
                })
                .catch((err) => {
                    this.$dbFunctions.setResponse(this, err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    },
    watch: {
        meta: {
            handler() {
                this.getList();
            },
            deep: true,
        },
        search: function (params) {
            this.getList();
        },
    },
    components: {
        InsertUpdateOperations: () =>
            import('@/components/systemUser/modals/InsertUpdateOperations'),
        DetailView: () => import('@/components/systemUser/modals/DetailView'),
    },
};
</script>
  